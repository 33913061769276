@use '../../variables';

.availableType {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #f8f8f8;
  border-radius: 40px;
  padding: 0.5rem 1.2rem;
  margin-bottom: 10px;

  &__left {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    h4 {
      font-family: 'NunitoBold';
      margin-left: 1rem;
      font-size: 0.875rem;
      color: variables.$brand;
    }
  }

  &-details {
    color: variables.$brand;
  }
}
