@use '../../variables';

.page-container {
  position: relative;
  width: 100%;

  // REMOVE THIS AND CLEAN PROPERLY
  overflow-y: hidden;

  .card-container {
    position: absolute;
    top: 40px;
    left: 40px;

    .card {
      width: 610px;
      height: 85vh;
    }
  }

  .mapboxgl-map {
    position: initial;
  }
}
