@use '../../variables';

.listItem {
  display: grid;
  grid-template-columns: min-content 1fr minmax(min-content, 3rem);
  width: 100%;

  background: #f8f8f8;
  border-radius: 25px;
  padding: 1rem;

  &__left {
    color: variables.$brand;
    margin-right: 0.7rem;
  }

  &__center {
    padding-right: 1rem;
    h4 {
      margin: 0;
      color: variables.$brand;
      font-family: 'NunitoMedium';
    }
  }

  &__right {
    display: flex;
    justify-content: flex-end;
  }
}
