@use '../../../variables';

.container {
  width: 610px;
}

.addBilling {
  display: flex;
  justify-content: flex-end;
}

.billingType-container {
  & > * {
    margin-bottom: 10px;
  }
}

.checkbox-group {
  display: flex;
  label:first-of-type {
    margin-right: 1rem;
  }
}

.cancel-btn {
  cursor: point;
  font-size: 1rem;
  color: #bdbdbd;
  background: transparent;
  border: none;
  outline: none;
}

.cvc {
  display: flex;
  align-items: center;
  & > .formInput {
    margin-bottom: 0;
  }
  margin-bottom: 1rem;

  svg {
    padding: 0 1.25rem;
  }
}
