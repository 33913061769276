@use '../../variables';

.loginBackground {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    .header {
      display: flex;
      align-items: center;
  
      img {
        width: 50px;
      }
      color: variables.$brand;
    }

    .switch-container {
      display: flex;
      justify-content: space-around;
      margin: 2rem 0;

      button.switch-btn {
        transition: all .3s;
        background: none;
        outline: none;
        cursor: pointer;
        border: none;
        border-bottom: 2px solid transparent;
        padding: 0 0 .3rem 0;

        font-size: 1rem;
        font-family: "NunitoBold";
        color: variables.$brand;

        &--selected { 
          border-color: variables.$focus;
        }

        &:not(.switch-btn--selected) {
          opacity: 30%;
        }
      }
    }
  
    .inlineForm__submit { 
      display: flex;
      justify-content: space-between;
      align-items: center;
  
    }
  
    .footer {
      & > * {
        margin-right: 1rem;
      }
  
      &__link {
        text-decoration: none;
        color: variables.$brand;
        font-family: 'NunitoBold';
      }
    }
  }
  