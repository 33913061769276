@use '../../../variables';

.integrations {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 19rem);
  gap: 1rem;

  & > .card {
    margin-bottom: 0;
  }

  margin-bottom: 2rem;
}

.integration {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;

    h4 {
      font-size: 1rem;
      font-family: 'NunitoBold';
      text-transform: uppercase;
      color: variables.$brand;
    }
  }

  &__logo {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 14rem;
    height: 14rem;
    object-fit: contain;
  }

  &-edit {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 4px;

    background-color: #f2f6f9;
  }

  &__category {
    h5 {
      font-size: 0.75rem;
      font-family: 'NunitoBlack';
      text-transform: uppercase;
      color: variables.$brand;
      font-weight: bold;

      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }

  &__footer {
    button {
      width: 100%;
      justify-content: center;
    }
  }
}
