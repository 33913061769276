@use '../../../variables';

.container {
  width: 610px;
}

.addresses-container {
  & > * {
    margin-bottom: 10px;
  }
}

.addAddress {
  display: flex;
  justify-content: flex-end;
}

.splitted {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
}
