@use '../../variables';

.delivery {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 800;

    h4 {
      font-size: 1rem;
      font-family: 'NunitoBlack';
      text-transform: uppercase;
      color: variables.$brand;
    }
  }

  &__time {
    width: max-content;
    background-color: variables.$accent-1;
    padding: 5px 10px;
    border-radius: 30px;

    .time {
      color: variables.$brand;
      font-family: 'NunitoBlack';
    }
  }

  &__category {
    &-title {
      font-size: 0.75rem;
      font-family: 'NunitoBlack';
      text-transform: uppercase;
      color: variables.$brand;
      font-weight: bold;

      margin-bottom: 0.75rem;
    }

    &-content {
      display: flex;
      align-content: center;
      gap: 0.75rem;
    }

    &-icon {
      display: flex;
      align-items: center;
      svg {
        color: variables.$brand;
      }
    }

    &-body {
      &-title {
        color: variables.$brand;
        font-family: 'NunitoLight';
      }

      &-subtitle {
        font-family: 'NunitoLight';
        color: variables.$dark-4;
      }
    }
  }
}
