@use './variables';

/* ***********************************************/
/* GLOBAL */
/* ***********************************************/

body {
  margin: 0;
  font-family: 'NunitoMedium';
}

/* ***********************************************/
/* SCREENS */
/* ***********************************************/

/* Login */

.loginBackground {
  position: absolute;
  background-image: url(./assets/img/background.png);
  background-size: 100% 100%;
  left: 0;
}

/* base */

*,
body,
html {
  box-sizing: border-box;
}

.heading {
  font-family: 'DMSerifText';
  font-weight: 400;
  color: variables.$brand;
  margin-top: 0;
}

.brand {
  color: variables.$brand;
}

.separator {
  height: 2px;
  width: 100%;
  background-color: variables.$light-grey;
  margin: 1.25rem 0;
}

.form-container {
  width: 610px;
  form {
    width: 100%;
  }
}

.input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr 1fr;
  column-gap: 20px;
  // margin-bottom: 1rem;

  & > * {
    margin: 0;
  }
}

.link {
  text-decoration: none;
  color: variables.$brand;
  font-family: 'NunitoBold';
}

.txtError {
  color: red;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.txtInputSuggestions {
  padding-top: 16.5px !important;
  padding-bottom: 16.5px !important;
  margin-bottom: 1rem !important;
  border: 2px solid #d8d8d8 !important;
  font-size: 0.925em !important;
}
