// Variables 

$focus: #f95b5b;
$brand: #151a40;
$placeholder: #f4f4f4;
$dark-4: #999999;
$light-grey: #d8d8d8;
$accent-1: #fbd983;
$accent-2: #82d794;
$white: #ffffff;
$danger: #b41212;
