@use '../../variables';

.card {
  padding: 40px;
  background: variables.$white;
  border-radius: 20px;

  margin-bottom: 20px;
  box-shadow: 0px 0px 40px rgba(48, 71, 94, 0.1);
}
