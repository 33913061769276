@use '../../../variables';

.stepper {
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  height: 100%;
  overflow: auto;

  .header {
    h1,
    h4 {
      margin: 0;
    }

    h4 {
      color: variables.$brand;
      font-size: 1rem;
      font-family: 'NunitoBold';
    }
    margin-bottom: 2rem;
  }

  .footer {
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .red {
    color: red;
    font-family: 'NunitoBlack';
  }

  .green {
    color: #82d794;
    font-family: 'NunitoBlack';
  }
}
