@use '../../variables';

.loginBackground {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .header {
    display: flex;
    align-items: center;

    img {
      width: 50px;
    }
    color: variables.$brand;
  }

  .inlineForm__submit { 
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .footer {
    & > * {
      margin-right: 1rem;
    }

    &__link {
      text-decoration: none;
      color: variables.$brand;
      font-family: 'NunitoBold';
    }
  }
}
