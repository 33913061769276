@use '../../variables';

.stepperRadio {
  margin-bottom: 1rem;

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    align-items: center;
  }
  &__icon {
    svg {
      color: variables.$brand;
    }
    margin-right: 1rem;
  }
  &__icon_disabled {
    svg {
      color: variables.$dark-4;
    }
    margin-right: 1rem;
  }

  &__details {
    h4,
    p {
      margin: 0;
    }

    h4 {
      font-size: 0.875rem;
      color: variables.$brand;
      font-family: 'NunitoBlack';
    }

    p {
      margin-top: -7px;
      font-size: 0.75rem;
    }
  }

  &__details_disabled {
    h4,
    p {
      margin: 0;
    }

    h4 {
      font-size: 0.875rem;
      color: variables.$dark-4;
      font-family: 'NunitoBlack';
    }

    p {
      margin-top: -7px;
      font-size: 0.75rem;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__price {
    margin-right: 1rem;
  }
}
