@use '../../variables';

.modal {
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.4);
  }

  &__close {
    color: variables.$brand;
    cursor: pointer;
  }

  &__card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__footer {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}