.ripple {
    height:128px;
    width: 128px;
    background-image: url('../../assets/img/ripple-rider.gif');
    background-position: center;
    background-size: contain;
    align-items: center;
    align-content: center;
    display: flex;
}

.pin-rider {
    align-self: center;
    align-content: center;
}