@use '../../variables';

.button {
  cursor: pointer;

  border: none;
  color: variables.$white;
  font-family: 'NunitoMedium';
  font-size: 1rem;
  padding: 20px;
  font-weight: 700;
  border-radius: 40px;
  height: 46px;
  margin-left: 5px;
  margin-right: 5px;

  display: flex;
  align-items: center;

  &--primary {
    background-color: variables.$brand;
  }

  &--focus {
    background-color: variables.$focus;
  }

  &--accent-1 {
    background-color: variables.$accent-1;
  }

  &--accent-2 {
    background-color: variables.$accent-2;
  }

  &--danger {
    background-color: variables.$danger;
  }

  &--neutral {
    background-color: variables.$light-grey;
  }

  &--placeholder {
    background-color: variables.$placeholder;
  }

  &--white {
    background-color: variables.$white;
    color:#bdbdbd !important;
  }

  transition: 0.3s ease-out;

  &:not(:disabled) {
    &:hover,
    &:focus,
    &:active {
      transform: translate(-5px, -5px);
      box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.5);
    }
  }

  &:disabled {
    cursor: default;
    background-color: #f2f2f2f2;
    color: #bdbdbd;
  }
}
