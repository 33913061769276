@use '../../variables';

label.formInput {
  position: relative;
  border: 2px solid variables.$light-grey;
  border-radius: 40px;
  padding-left: 1rem;
  color: variables.$dark-4;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;

  &.formInput--fail {
    border-color: variables.$danger;
  }

  .input__body {
    position: relative;
    width: 100%;
  }

  .input__left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }

  input,
  select {
    width: 90%;
    padding: 10px 0px;
    margin-top: 20px;
    border: none;
    outline: none;
    color: #151a40;
    font-size: 0.925em;
    background-color: transparent;
  }

  input::placeholder {
    opacity: 0;
  }

  input + span {
    position: absolute;
    top: 10px;
    // left: 1rem;
    left: 0;
    transform: translateY(30%);
    font-size: 0.85em;
    transition-duration: 300ms;
    font-weight: 700;
  }

  select + span {
    position: absolute;
    top: 5px;
    // left: 1rem;
    left: 0;
    transform: translateY(30%);
    font-size: 0.85em;
    transition-duration: 300ms;
    font-weight: 700;
  }

  &:focus-within span,
  input:not(:placeholder-shown) + span,
  select:has(option:checked) + span {
    transform: translateY(0px);
  }
}
