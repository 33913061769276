/* fonts */

@font-face {
  font-family: 'DMSerifText';
  src: url('./assets/fonts/dm/DMSerifText-Regular.ttf');
}

@font-face {
  font-family: 'NunitoMedium';
  src: url('./assets/fonts/nunito/static/Nunito-Medium.ttf');
}

@font-face {
  font-family: 'NunitoBlack';
  src: url('./assets/fonts/nunito/static/Nunito-Black.ttf');
}

@font-face {
  font-family: 'NunitoBold';
  src: url('./assets/fonts/nunito/static/Nunito-Bold.ttf');
}

@font-face {
  font-family: 'NunitoLight';
  src: url('./assets/fonts/nunito/static/Nunito-Light.ttf');
}

/* base */
