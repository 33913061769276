.deliveries {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20.3125rem);
  gap: 1rem;

  & > .card {
    margin-bottom: 0;
  }

  margin-bottom: 2rem;
}
