@use '../../../../variables';

.masked-component-input {
  input {
    border: 2px solid variables.$light-grey;
    border-radius: 40px;
    padding-left: 1rem;
    color: #6a6a6a;
    // color: variables.$dark-4;
  }

  input::placeholder {
    color: #6a6a6a !important;
    // opacity: 1 !important;
  }
}

.masked-component-date {
  & > div {
    border: 2px solid variables.$light-grey;
    border-radius: 40px;
  }

  input { 
    border: none;
    outline: none;

    padding-left: 1rem;

    &:is(:focus, :active) { 
        box-shadow: none;
    }
  }
}
